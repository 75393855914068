import { PageMobile } from "components/routing/page/page/page-mobile";
import { getAnalytics } from "logic/analytics/analytics";
import { useAuthContext } from "logic/auth-context";
import { EnvironmentVariables } from "logic/internals/runtime/environment-variables";
import posthog from "posthog-js";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { useGetCurrentUserQuery } from "store/modules/users/slice";
import { IndexContainer } from "./desktop/index-container";
import { INDEX_ROUTE } from "./index-routes";
import { IndexContainerMobile } from "./mobile/index-container";
import { useFeatureFlagEnabled } from "posthog-js/react";

export default function IndexTemplate() {
  const isMaintenance = EnvironmentVariables.MAINTENANCE_MODE === "true";

  // FEATURE FLAG
  const ragFeatureFlagEnabled = useFeatureFlagEnabled("rag");
  const surveysFeatureFlagEnabled = useFeatureFlagEnabled("surveys");

  const workspace = useAppSelector((state) => state.workspaces.data.workspace);
  const project = useAppSelector((state) => state.projects.data.project);
  const currentUser = useAppSelector((state) => state.currentUser);
  const auth = useAuthContext();
  const firebaseUser = auth.session.user;

  useGetCurrentUserQuery(undefined, {
    skip: !project,
  });

  /**
   * Analytics setup and user identification
   *
   * This is the place where you should identify the user in all the analytics tools
   *
   * For now, we have Segment, Intercom and Posthog
   */
  const analytics = getAnalytics();

  useEffect(() => {
    if (firebaseUser && currentUser.data.currentUser && project) {
      // Segment identify
      analytics.identify({
        userId: currentUser.data.currentUser.id,
        firebaseId: firebaseUser.uid,
        email: firebaseUser.email || undefined,
      });

      // Identify user with Posthog
      posthog.identify(currentUser.data.currentUser.id, {
        email: firebaseUser.email,
        name: firebaseUser.displayName,
        workspaceId: workspace?.id,
        projectId: project.id,
      });
    }
  }, [firebaseUser, currentUser, project, analytics, workspace?.id]);

  return (
    <PageMobile
      title={INDEX_ROUTE.label}
      requiresLogin={false}
      desktop={
        <IndexContainer
          isMaintenance={isMaintenance}
          ragEnabled={ragFeatureFlagEnabled}
          surveysEnabled={surveysFeatureFlagEnabled}
        />
      }
      mobile={<IndexContainerMobile isMaintenance={isMaintenance} />}
    />
  );
}
